<template>
  <div id="app">
    <main>
      <HelloWorld />
    </main>
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
export default {
  name: "App",
  components: {
    HelloWorld,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;700&display=swap");
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Source Sans 3", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #f8f9fa; */
  background: rgba(236, 236, 236);
}

#app {
  text-align: center;
  color: #2c3e50;
}

header {
  margin: 0;
  padding: 0;
}

nav {
  margin: 0;
  padding: 0;
}
</style>
